@import url(https://fonts.googleapis.com/css2?family=Marck+Script&display=swap);
html, body, #root, .container {
    height: 100%;
    overflow-y: hidden;
}

.map{               
    height: 100%;
}
.centered {
    position: fixed;
    top: 50%;
    left: 50%;    
    transform: translate(-50%, -50%);
}
.main-loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;    
}
.main-loader-text {
    font-size: 2em;
    color: #fff;
    text-shadow: 0 0 10px #00b3ff,
                 0 0 20px #00b3ff,
                 0 0 20px #00b3ff,
                 0 0 20px #00b3ff,
                 0 0 20px #00b3ff;
}
.side-drawer-container {
    position: fixed;
    top: 0%;
    left: 100%;    
    transform: translate(-100%, 0%);
    z-index: 999999;    
    height: 100%;
    background-color: #fff;
}
.side-drawer-container-collapsed {
    width: 0;
}
.logo-text {   
    font-family: 'Marck Script', cursive;
    font-size: 4em;
}
.logo-placeholder{
    width: 150px;
}

